var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bankAccount
    ? _c("div", [
        _c("div", { staticClass: "field" }, [
          _c(
            "label",
            { staticClass: "label", attrs: { for: "accountNumber" } },
            [_vm._v("Número da conta")]
          ),
          _c("div", { staticClass: "field" }, [
            _c("input", {
              staticClass: "ui input disabled",
              attrs: {
                type: "text",
                id: "accountNumber",
                name: "account[number]",
                disabled: ""
              },
              domProps: { value: _vm.accountNumber }
            })
          ])
        ]),
        _c("div", { staticClass: "field" }, [
          _c(
            "label",
            { staticClass: "label", attrs: { for: "accountAdNumber" } },
            [_vm._v("Número da agência")]
          ),
          _c("div", { staticClass: "field" }, [
            _c("input", {
              staticClass: "ui input disabled",
              attrs: {
                type: "text",
                id: "accountAdNumber",
                name: "account[agnumber]",
                disabled: ""
              },
              domProps: { value: _vm.bankAccount.agencyNumber }
            })
          ])
        ]),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label", attrs: { for: "accountBank" } }, [
            _vm._v("Banco")
          ]),
          _c("div", { staticClass: "field" }, [
            _c("input", {
              staticClass: "ui input disabled",
              attrs: {
                type: "text",
                id: "accountBank",
                name: "account[bank]",
                disabled: ""
              },
              domProps: { value: _vm.bankAccount.bankName }
            })
          ])
        ]),
        _vm.customer
          ? _c("div", { staticClass: "field" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "accountName" } },
                [_vm._v("Titular")]
              ),
              _c("div", { staticClass: "field" }, [
                _c("input", {
                  staticClass: "ui input disabled",
                  attrs: {
                    type: "text",
                    id: "accountName",
                    name: "account[name]",
                    disabled: ""
                  },
                  domProps: { value: _vm.customer.fullName }
                })
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }