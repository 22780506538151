<template>
  <div v-if="bankAccount">
    <div class="field">
      <label for="accountNumber" class="label">Número da conta</label>
      <div class="field">
        <input type="text" id="accountNumber" name="account[number]" class="ui input disabled" disabled :value="accountNumber">
      </div>
    </div>

    <div class="field">
      <label for="accountAdNumber" class="label">Número da agência</label>
      <div class="field">
        <input type="text" id="accountAdNumber" name="account[agnumber]" class="ui input disabled" disabled :value="bankAccount.agencyNumber">
      </div>
    </div>

    <div class="field">
      <label for="accountBank" class="label">Banco</label>
      <div class="field">
        <input type="text" id="accountBank" name="account[bank]" class="ui input disabled" disabled :value="bankAccount.bankName">
      </div>
    </div>

    <div class="field" v-if="customer">
      <label for="accountName" class="label">Titular</label>
      <div class="field">
        <input type="text" id="accountName" name="account[name]" class="ui input disabled" disabled :value="customer.fullName">
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TFormDebitAccount',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      donation: ({ donations }) => donations.donationDetail,
      customer: ({ donations }) => donations.donationDetail.customer,
      bankAccount: ({ donations }) => donations.donationDetail.bankAccount,
    }),
    accountNumber: ({ bankAccount }) => `${bankAccount.accountNumber}-${bankAccount.accountDigit}`
  }
}
</script>
